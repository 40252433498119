.image.image--heart-shape {
  width: 100%;
  aspect-ratio: 1;
  $radial:
    radial-gradient(at 70% 31%, #000 29%, #0000 29%),
    radial-gradient(at 30% 31%, #000 29%, #0000 29%),
    linear-gradient(#000 0 0) bottom/100% 50% no-repeat;
  -webkit-mask: $radial;
  mask: $radial;
  clip-path: polygon(-41% 0, 50% 91%, 141% 0);

  img {
    padding: 2.5% 1% 9%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
