.countdown {
  &__slideshow {
    align-content: end;

    &-title {
      margin-block-start: 0;
      margin-block-end: .75rem;
      text-align: center;
    }

    &-slider {
        max-width: 35rem;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 768px) {
      align-content: center;

      &-title {
        margin-block-end: 1.5rem;
      }
    }
  }

  &__counter {
    &-title {
      margin-block-start: 0;
      margin-block-end: .75rem;
      text-align: center;
    }

    @media (min-width: 768px) {
      align-content: center;

      &-title {
        margin-block-end: 1.5rem;
      }
    }
  }
}
