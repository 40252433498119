.col {
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

$breakpoints: (
  "xs": "0px",
  "sm": "576px",
  "md": "768px",
  "lg": "992px"
);

@each $name, $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    @for $i from 1 through 12 {
      .col-#{$name}-#{$i} {
        flex: 0 0 auto;
        width: calc(100% / 12 * $i);
      }
    }
  }
}
