.page {
  width: 100dvw;
  height: 100dvh;

  &--theme {
    &-default {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../public/images/watercolor-background.jpg");
    }

    &-winter {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../public/images/watercolor-background-blue.jpg");
    }
  }
}